






































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, maxLength, ValidationRule } from 'vuelidate/lib/validators'
import { negativeDouble } from '@/app/infrastructures/misc/ValidationRules'
import Button from '@/app/ui/components/Button/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import controller from '@/app/ui/controllers/MerchantController'
import brandController from '@/app/ui/controllers/BrandController'
import routeController from '@/app/ui/controllers/RouteController'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import MerchantTextInput from '../../components/MerchantTextInput/index.vue'
import MerchantDropdown from '../../components/MerchantDropdown/index.vue'
import { MerchantDetail } from '@/domain/entities/Merchant'
import { Brand } from '@/domain/entities/Brand'

interface DropDownInterface {
  value: string | number
  label: string
}

interface Form {
  profile: {
    name: string
    brand: DropDownInterface | null
    city: DropDownInterface | null
    district: DropDownInterface | null
    description: string
    address: string
    longitude: number | null
    latitude: number | null
    banner: Blob | null
  }
  bank: {
    bankName: string
    accountName: string
    accountNumber: string
  }
}

interface ValidationsInterface {
  form: {
    profile: {
      name: {
        required: () => ValidationRule
        maxLength: ValidationRule
      },
      brand: {
        required: () => ValidationRule
      },
      city: {
        required: () => ValidationRule
      },
      district: {
        required: () => ValidationRule
      },
      description: {
        required: () => ValidationRule
      },
      address: {
        required: () => ValidationRule
        maxLength: ValidationRule
      },
      latitude: {
        required: () => ValidationRule
        negativeDouble: ValidationRule
      },
      longitude: {
        required: () => ValidationRule
        negativeDouble: ValidationRule
      },
      banner: {
        required: boolean
      },
    }
    bank: {
      bankName: {
        required: () => ValidationRule
      },
      accountName: {
        required: () => ValidationRule
      },
      accountNumber: {
        required: () => ValidationRule
      }
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    MerchantTextInput,
    MerchantDropdown,
    Button,
    FileInput,
    Modal,
    LoadingOverlay,
  },
})
export default class UpdateMerchant extends Vue {
  controller = controller
  brandController = brandController
  routeController = routeController

  form: Form = {
    profile: {
      name: '',
      brand: null,
      city: null,
      district: null,
      description: '',
      address: '',
      latitude: null,
      longitude: null,
      banner: null,
    },
    bank: {
      bankName: '',
      accountName: '',
      accountNumber: '',
    },
  }
  confirmationModal = false
  successModal = false

  brandList: DropDownInterface[] = []

  created(): void {
    controller.getMerchantDetail(this.$route.params.id)
    this.fetchCityList()
    this.fetchBrandList()
  }

  @Watch('controller.statusUpdateMerchant')
  onStatusUpdateMerchantChanged(status: string): void {
    if (status !== '' && status === EventBusConstants.UPDATE_MERCHANT_SUCCESS) {
      this.confirmationModal = false
      this.successModal = true
    }
    controller.setStatusUpdateMerchant('')
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        profile: {
          name: {
            required,
            maxLength: maxLength(30),
          },
          brand: {
            required,
          },
          city: {
            required,
          },
          district: {
            required,
          },
          description: {
            required,
          },
          address: {
            required,
            maxLength: maxLength(100),
          },
          latitude: {
            required,
            negativeDouble,
          },
          longitude: {
            required,
            negativeDouble,
          },
          banner: {
            required: false,
          },
        },
        bank: {
          bankName: {
            required,
          },
          accountName: {
            required,
          },
          accountNumber: {
            required,
          },
        },
      },
    }
  }

  private changeImage(file: File): void {
    this.form.profile.banner = file
  }

  private updateMerchant(): void {
    controller.updateMerchant({
      id: Number(this.$route.params.id),
      ...this.form,
    })
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({
      name: 'MerchantDetailProfilePage',
      params: this.$route.params,
    })
  }

  private fetchBrandList(search?: string): void {
    if (search === '') search = undefined
    brandController.getBrandList({
      perPage: 30,
      name: search,
    })
  }

  private brandSearch = Utils.debounce((search: string): void => {
    this.fetchBrandList(search)
  }, 500)

  private fetchCityList(): void {
    routeController.getCityList({ value: 'name-lc' })
  }

  private fetchDistrictList(city: string): void {
    routeController.getCityDistrictList({ city, options: { value: 'name' } })
  }

  @Watch('controller.merchantDetail')
  onMerchantDetailChanged(val: MerchantDetail): void {
    this.form = {
      profile: {
        name: <string>val.name,
        brand: <DropDownInterface>val.brand,
        city: <DropDownInterface>val.city,
        district: <DropDownInterface>val.district,
        description: <string>val.description,
        address: <string>val.address,
        latitude: <number>val.latitude,
        longitude: <number>val.longitude,
        banner: null,
      },
      bank: {
        bankName: <string>val.bank,
        accountName: <string>val.bankAccountName,
        accountNumber: <string>val.bankAccountNumber,
      },
    }
  }

  @Watch('brandController.brandData')
  onBrandDataChanged(val: Brand[]): void {
    this.brandList = val.map((brand) => {
      return {
        value: <number>brand.id,
        label: <string>brand.name,
      }
    })
  }

  get cityList(): DropDownInterface[] {
    return routeController.cityData
  }

  get districtList(): DropDownInterface[] {
    return routeController.districtData
  }

  @Watch('form.profile.city')
  onCityChanged(val: DropDownInterface | null): void {
    if (val && val.value) {
      if (this.$v.form.profile?.city.$dirty === true) {
        this.form.profile.district = null
      }
      this.fetchDistrictList(<string>val.value)
    }
  }

  beforeDestroy(): void {
    controller.setStatusUpdateMerchant('')
  }
}
